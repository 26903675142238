import React from 'react';
import {
    Switch,
    Route,
    NavLink,
    Redirect,
    Link,
    useParams,
    useRouteMatch
  } from 'react-router-dom';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core'

import Navbar from './components/Navbar';
import Projects from './pages/Projects';
import About from './pages/About';
import Resume from './pages/Resume';
import Welcome from './pages/Welcome';

// const resume = require('./images/Resume.pdf');

function App() {

  return (
    <div>
      <Global styles={css`
            @import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');
            body{
                margin:0px;
                padding: 0px;
                background-color: GhostWhite;
                width: 100%;
                height: 100%;
            }
            html{
                font-family: 'Ubuntu', sans-serif; 
                width: 100%;
                height: 100%;
                margin: 0%;
                padding: 0%;
            }
            h1{
                margin-left: 3%; 
                color: Black;
            }
            button{
                background-color: rgba(255,0,0,1);
                color: Black;
            }
    `}/>

      <Navbar />
      <Switch>
        <Route path="/projects">
          <Projects/>
        </Route>
        <Route path="/resume">
          <Resume />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route exact path="/">
          <Welcome />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
