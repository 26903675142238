import React from 'react';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import SimpleImageSlider from "react-simple-image-slider";
import allImages from './../components/images'

function Project({ title, url, date, text }) {
  const color = "rgb(52, 152, 219)";

  const project = css `
    display: grid;
    grid-template-columns: 35% 65%;
    background-color: GhostWhite;
    box-shadow: 1px 1px 10px 1px;
    margin: 2%;
    padding-left: 1%;
    a {
      color: rgb(231, 76, 60);
    }
    p{
      font-size: 18px;
    }
    @media (max-width: 768px){
      display: grid;
      grid-template-columns 100%
    }
  `;

  const slider = css `
  margin-left: auto;
  @media (max-width: 768px){
    width: 100%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  `
  var images = []
  for (var i in allImages[title]){
    var image = {}
    image.url = allImages[title][i];
    images.push(image);
  }
  var bullets = [];
  for (var t in text){
    bullets.push(text[t])
  }
  if(window.innerWidth >=768){
    return (
      <article css={project}>
        <div>
          <h2><a href={url} target="_blank">{title}</a></h2>
          <h3 css={css`right: 0px;`}>{date}</h3>
          <ul>{bullets.map((bullet, i) => <Bullet key={i} text={bullet} />)}</ul>
        </div>
        <div className="slider" css={slider}>
          <SimpleImageSlider css={slider} width={window.innerWidth*.6} height={window.innerWidth*.6*.5625} images={images}/>
        </div>
      </article>
    );
  }
  else{
    return (
      <article css={project}>
        <div>
          <h2><a href={url} target="_blank">{title}</a></h2>
          <h3 css={css`right: 0px;`}>{date}</h3>
          <ul>{bullets.map((bullet, i) => <Bullet key={i} text={bullet} />)}</ul>
        </div>
        <div className="slider" css={slider}>
          <SimpleImageSlider css={slider} width={window.innerWidth*.9} height={window.innerWidth * .9 *.5625} images={images}/>
        </div>
      </article>
    );
  }
  return (
    <article css={project}>
      <div>
        <h2><a href={url} target="_blank">{title}</a></h2>
        <h3 css={css`right: 0px;`}>{date}</h3>
        <ul>{bullets.map((bullet, i) => <Bullet key={i} text={bullet} />)}</ul>
      </div>
      <div className="slider" css={slider}>
        <SimpleImageSlider css={slider} width={window.innerWidth*.6} height={window.innerWidth*.6*.5625} images={images}/>
      </div>
    </article>
  );
}

function Bullet (props){
  const bulletStyle = css `
    font-size: 20px;
    line-height: 1.5;
    @media (max-width: 768px){
      font-size: 18px;
    }`;
  
    return(
    <li css={bulletStyle}>{props.text}</li>
  );
}

export default Project;

