const syncify1 = require("./../images/syncify-1.png")
const syncify2 = require("./../images/syncify-2.png")
const syncify3 = require("./../images/syncify-3.png")

const spotlist1 = require("./../images/Spotlists-1.png");
const spotlist2 = require("./../images/Spotlists-2.png");
const spotlist3 = require("./../images/Spotlists-3.png");
const spotlist4 = require("./../images/Spotlists-4.png");

const bookscan1 = require("./../images/bookscan-1.png")
const bookscan2 = require("./../images/bookscan-2.png")
const bookscan3 = require("./../images/bookscan-3.png")

const tarpaulin1 = require("./../images/tarpaulin-1.png");
const tarpaulin2 = require("./../images/tarpaulin-2.png");
const tarpaulin3 = require("./../images/tarpaulin-3.png");
const tarpaulin4 = require("./../images/tarpaulin-4.png");

const ideas1 = require("./../images/IDEAS-1.png");
const ideas2 = require("./../images/IDEAS-2.png");

const images = {
  "Syncify": [syncify1, syncify2, syncify3],
  "Spotlists": [spotlist1, spotlist2, spotlist3, spotlist4],
  "Book Scan": [bookscan1, bookscan2, bookscan3],
  "Tarpaulin API Server": [tarpaulin4, tarpaulin1, tarpaulin2, tarpaulin3, ],
  "Intelligent Deep Annotator for Segmentation (IDEAS)": [ideas1, ideas2]
}

export default images;
