import React from 'react';
import Project from '../components/Project';
import projects from '../data/projects.json';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/core'

function Projects() {
  const header = css`
    margin-top: 0%;
    position: relative;
    padding-top: 0%;
    @media (max-width: 768px){
      padding-top: 15%;
      z-index: -1;
    }`

  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
    })
  
    React.useEffect(() => {
      function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      } 
  
      window.addEventListener('resize', handleResize)
  
      return _ => {
        window.removeEventListener('resize', handleResize)
      }
    })
    
  return (
    <div>
      <h1 css={header}>Projects</h1>
      <div>
        {projects.map((proj, i) => <Project key={i} {...proj} />)}
      </div>
    </div>
  );
}

export default Projects;
