import React from 'react';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/core'

const shortWords = [
  "C",
  "C++",
  "design",
  "honest",
  "curious",
  "quality",
  "Python",
  "Java",
  "HTML",
  "CSS",
  "SQL",
  "UNIX",
  "Agile",
  "Docker",
  "iOS",
  "leader",
  "MacOS",
  "Node JS",
  "PHP"
]
const longWords = [
  "professional",
  "research",
  "character",
  "passionate",
  "Android",
  "Windows",
  "positive",
  "co-author",
  "React JS",
  "teamwork",
  "collaboration",
  "self-motivated",
  "Singularity",
  "Bachelor's Degree",
  "computer science",
  "Haskell",
  "responsible",
  "basketball",
  "Portland",
  "Oregon State University",
  "music"
]

function getWidth() {
  return Math.max(
    // document.body.scrollWidth,
    // document.documentElement.scrollWidth,
    // document.body.offsetWidth,
    // document.documentElement.offsetWidth,
    // document.documentElement.clientWidth
    window.innerWidth
  );
}

function getHeight() {
  return Math.max(
    // document.body.scrollHeight,
    // document.documentElement.scrollHeight,
    // document.body.offsetHeight,
    // document.documentElement.offsetHeight,
    // document.documentElement.clientHeight
    window.innerHeight
  );
}

function Bubble({r, x, y, color, bId, word}) {
  switch(color){
    case 0:
      color = "rgb(52, 152, 219)"; //blue
      break;
    case 1:
      color = "rgb(231, 76, 60)"; //red
      break;
    default:
      color = "rgb(93, 109, 126)";  //grey
      break;
  }
  const wordLen = word.length;
  if (r < 40){
    return (
      <circle className={`bId-${bId}`} r={r} cx={x} cy={y} stroke={color} strokeWidth={r/10} fill="rgba(255,255,255,0.3)" />
    );
  }
  else{
    return (
      <svg>
        <circle className={`bId-${bId}`} r={r} cx={x} cy={y} stroke={color} strokeWidth={r/10} fill="rgba(255,255,255,0.3)" />
        <text className={`bId-${bId}`} x={(x-(wordLen*4))} y={y} fill={color}>{word}</text>
      </svg>
    );
  }
}

function Welcome(){
  const bubbles = [];
  const animations = [];
  var shortCount = 0;
  var longCount = 0;
  const rise = keyframes`
    from, 1%, to{
    transform: translate3d(0,${getHeight()}px,0);
    } 
    100% {
      transform: translate3d(0,${-1*getHeight()-125}px,0);
    }`

  const welcome = css`
    text-align: center; 
    font-size: 70px; 
    color:"black";
    z-index: -1;
    position: absolute;
    width: ${getWidth()}px;
    top: ${getHeight()/2}px;
    background-color: rgba(0,0,0,0);
    
    body {overflow: hidden;}
    `
  
  const [dimensions, setDimensions] = React.useState({ 
  height: window.innerHeight,
  width: window.innerWidth
  })

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    } 

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })
  var numBubbles = 85;
  if (getWidth() <= 768 || navigator.hardwareConcurrency < 8){
    numBubbles = 40;
  }
  for (var i = 0; i < numBubbles; i++){
    const bubObj = {
      r: (Math.random() * 100) % 60 + 35,
      x: (Math.random() * 20000) % getWidth(),
      y: (Math.random() * 1000),
      color: Math.floor((Math.random() * 3)),
      bId: i,
      word: " "
    }
    if (bubObj.r >= 40 && bubObj.r < 70 && shortWords[shortCount] !== undefined){
      bubObj.word = shortWords[shortCount];
      shortCount++;
    }
    else if (bubObj.r >= 70 && longWords[longCount] !== undefined){
      bubObj.word = longWords[longCount];
      longCount++;
    }
    else{
      bubObj.word = " ";
    }

    bubbles.push(bubObj)
    animations.push(css`.bId-${i}{
      animation: ${rise} ${bubbles[i].r/7}s infinite linear;
      z-index: 10;
      height: 10px;
    }`)
  }
  
  return (
    <div>
      <div css={welcome}>Welcome</div>
      <svg css={animations} width={getWidth()} height={getHeight()}>
        {bubbles.map((b, i) => <Bubble key={i} {...b} />)}
      </svg>
    </div>
  );
}

export default Welcome;
