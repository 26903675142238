import React from 'react';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core'
const me = require("./../images/Me.png");

function About() {
  const header = css`
    margin-top: 0%;
    position: relative;
    padding-top: 0%;
    @media (max-width: 768px){
      padding-top: 15%;
      z-index: -1;
    }`

  const colGrid = css`
    display: grid; 
    grid-template-columns: 25% 75%;
    grid-gap: 5%;
    font-size: 18pt;
    padding-bottom: 3rem;
    position: relative;
    @media (max-width: 950px){
      font-size: 16pt;
    }
    @media (max-width: 768px){
      font-size: 14pt;
      display: block;
      img{
        display: block;
        width: 40%;
        margin-right: auto;
        margin-left: auto;
      }
    }
  `;
  const rowGrid = css`
    display: grid;
    grid-template-rows: 5% 40% 55%;
    grid-gap:3%;
    width: 75%;
    @media (max-width: 768px){
      display: block;
      position: relative;
      margin: auto;
      text-align: center;
      p {width: 90%; margin: 5%;}
      ul {width: 90%;}
      h2 {margin: auto;}
    }
  `;

  return (
    <div>
      <div css={css`position: relative; min-height: 95vh;`}>
        <h1 css={header}>About Me</h1>

        <div css={colGrid}>
          <img css={css`width:100%; margin-left: 3%;`} src={me} alt={"Me at Santa Monica, CA"}/>

          <div css={rowGrid}>
            <h2 css={css`color: rgb(231, 76, 60); width: 50%; margin: 0%;`}>Nick Kiddle</h2>

            <p css={css`width: 80%; color: rgb(93, 109, 126);margin: 0%;`}>
              I am a recent university graduate from Oregon State University. 
              I graduated with a Bachelor of Science in Computer Science and 
              finished my last year on the Dean’s List each term with an average 
              GPA of 3.91 over that time. During college I worked as frontend 
              developer on a research team at Oregon State University and with 
              Apple doing iOS product troubleshooting.
            </p>

            <ul css={css`width: 80%; color: rgb(52, 152, 219);margin: 0%;`}>
              <li>In my sparetime I closely follow the Portland Trailblazers.</li>
              <li>On top of watching basketball I hoop casually on my own.</li>
              <li>Spending time out with friends is always important and a blast.</li>
              <li>You can always catch me looking for a DIY solution to my problems. I love to tinker and learn.</li>
              <li>Favorite bands include: Red Hot Chili Peppers, Foo Fighters, David Bowie, Queen, The Beatles and many more just ask!</li>
              <li>I like to play video games to unwind at the end of a long day.</li>
            </ul>
          </div>
        </div>
        <footer css={css`color: grey; bottom: 0%; position: absolute; margin: auto; width: 100%; text-align: center; height: 3rem;`}>
            <div>Contact</div>
            <div>ngkiddle@gmail.com | 503-709-8326</div>
          </footer>
        </div>
    </div>
  );
}

export default About;
