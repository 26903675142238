import React, { useState, useEffect } from 'react';
import {
    NavLink,
    Link
  } from 'react-router-dom';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
const logo = require("./../images/NK.png");

function Navbar(){
  const [toggleView, setView] = useState("none");
  const [toggleHamburger, setHamburger] = useState("relative");

  const navContainer = css `
    background-color: rgb(52, 152, 219);
    width: 100%;
    height: 10%;
    display: block;
    position: static;
    @media (max-width: 768px) {
      position: relative;
      background-color: rgba(52, 152, 219, 0);
    }`;
      
  const ulist = css`
    #nav-list{
      display: inline-block;
      background-color: rgb(52, 152, 219);
      height: 41px;
      margin-top: 0px;
      margin-bottom: 0px;
      width: 100%;
      img{
        display:inline;
        margin-right: 5%;
        margin-left: 1%;
        background-color: rgba(0,0,0,0);
      }
      div.nav{
        display: inline;
      }
      a.nav:hover{
        color: GhostWhite;
        background-color: rgb(231, 76, 60);
        padding: 6px;
      }
      a{
        display: inline;
        text-decoration: none;
        font-size: 24px; 
        color: GhostWhite;
        background-color: rgb(52, 152, 219);
        padding: 6px;
        height: 100%;                  
        &.active{
          color: GhostWhite;
          background-color: rgb(231, 76, 60);
          padding: 6px;
        }
      }
    }
    @media (max-width: 768px) {
      #nav-list{
        display: ${toggleView};
        top: 0%;
        width: 100%;
        left: 0%;
        height: 100%;
        position: relative;
        margin: 0px;
        padding: 0px; 
        img{
          display: inline;
          margin-right: 5%;
          margin-left: 1%;
          margin-bottom: 5%;
        }
        div.nav{
          display: block;
          margin: 5px;
        }   
        a.nav:hover{
          color: GhostWhite;
          background-color: rgb(231, 76, 60);
          padding: 0px;
        }
        a{
          padding: 0%;
          position: relative;
          height: 100%;
          width: 100%;
          display: block;                       
          &.active{
            color: GhostWhite;
            background-color: rgb(231, 76, 60);
            padding: 0px;
          }
        }
      }
    }
  }`;
    const arrow = css`
            display: none;
            @media (max-width: 768px){
                background-color: rgb(52, 152, 219);
                color: rgb(231, 76, 60);
                font-size: 25px;
                margin: 0px;
                display: block;
                text-align: center;
                border: 2px solid rgb(231, 76, 60);
                border-radius: 35px;
                width: 40px;
                height: 40px;
                position: relative;
                margin: 10px;
                z-index: 15;
                line-height: 1.5;
                float: right;
            }`;

    const hamburger = css`
            display: none;
            @media (max-width: 768px){
              position: ${toggleHamburger};
              color: rgb(231, 76, 60);
              font-size: 25px;
              margin: 10px;
              background-color: rgb(52, 152, 219);
              display: block;
              text-align: center;
              border: 2px solid rgb(231, 76, 60);
              border-radius: 25px;
              width: 40px;
              height: 40px;
              line-height: 1.5;
              z-index: 0;
            }`;

    return (
        <div>
          <div id="nav-container" css={[ulist, navContainer]}>
            <div css={hamburger} onClick={()=>{setView("block"); setHamburger("absolute");}}>{'\u2630'}</div>
            <div id="nav-list" css={css`display:${toggleView};`}> 
                <div css={arrow} onClick={()=>{setView("none"); setHamburger("relative");}}>{'\u276E'}</div>
                <Link to="/">
                  <img css={css`padding-top:5px`} src={logo} alt="Nicholas Kiddle" width="30" height="25"/>
                </Link>
                <div className="nav"></div>
                <NavLink className="nav" exact to="/">Home</NavLink>
                <NavLink className="nav" to="/projects">Projects</NavLink>
                <NavLink className="nav" to="/about">About Me</NavLink>
                <NavLink className="nav" to="/resume">R{'\xE9'}sum{'\xE9'}</NavLink>
            </div>
          </div>
        </div>
    );
}
export default Navbar;
