import React from 'react';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core'
const resume1 = require("./../images/Resume1.png");
const resume2 = require("./../images/Resume2.png");

function Resume(){
  const resumeImg = css `
    margin-right: auto;
    margin-left: auto;
    padding: 1%;
    // padding-bottom: 2%;
    // padding-left:
    display: block;
   //background-color: rgb(93, 109, 126);
    filter: drop-shadow(20px 10px 10px rgb(93, 109, 126));
  `;

  return (
    <div>
      <img css={resumeImg} src={resume1} alt="Resume Page 1" width="800" />
      <img css={resumeImg} src={resume2} alt="Resume Page 2" width="800" />
    </div>
  );
}

export default Resume;
